<template>
  <div class="h-screen flex overflow-y-auto bg-white">
    <div class="flex flex-col w-0 flex-1">
      <home-header />
      <main class="flex-1 relative focus:outline-none" tabindex="0">
        <div class="py-6">
          <div class="mx-auto px-4 sm:px-6 md:px-8 flex flex-col">
            <div id="vue-root" class="text-black text-base sm:text-lg">
              <p class="font-bold text-lg text-center py-2">Account Setup</p>
              <p class="font-medium italic py-2">
                Please ensure all your details are correct and edit the fields
                if possible to the correct values. Please refer to your
                preceptory's Registrar if any other details are incorrect.
              </p>
              <form id="registerMember" @submit.prevent="checkForm">
                <div
                  class="w-full px-4 lg:px-6 my-4 grid grid-flow-row gap-4 lg:gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-base 2xl:text-xl"
                >
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Email</p>
                    <input
                      type="email"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="email"
                      placeholder="Email"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                      disabled
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Mobile Phone</p>
                    <input
                      type="tel"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="phone"
                      placeholder="+6512345678"
                      pattern="\+[0-9]+$"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Occupation</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2"
                      v-model="occupation"
                      placeholder="Occupation"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Title</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="title"
                      placeholder="Title"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                      disabled
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">MMH-ID</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="mmhid"
                      placeholder="MMH-ID"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                      disabled
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Surname</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="surname"
                      placeholder="Surname"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                      disabled
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Given Name</p>
                    <input
                      type="text"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="name"
                      placeholder="Given Name"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                      disabled
                    />
                  </div>

                  <div class="flex flex-col">
                    <p class="underline font-semibold">Date Of Birth</p>
                    <input
                      type="date"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="birthDate"
                      placeholder="Date of birth"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                      disabled
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Date of Joining</p>
                    <input
                      type="date"
                      class="form-control rounded-md mb-3 border-gray-300 mt-2 disabled:bg-gray-200"
                      v-model="joinDate"
                      placeholder="Date of joining"
                      @keydown="$event.stopImmediatePropagation()"
                      required
                      disabled
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Role</p>
                    <select
                      class="form-select block w-full mt-2 disabled:bg-gray-200"
                      v-model="selectedRole"
                      required
                      disabled
                    >
                      <option v-for="role in roleList" :key="role.roleId">
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-full px-4 lg:px-6 my-4 lg:my-8">
                  <div class="flex flex-col">
                    <p class="underline font-semibold">Preceptory</p>
                    <multiselect
                      v-model="selectedPreceptories"
                      :options="preceptoryList"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Select preceptory"
                      label="name"
                      track-by="name"
                      :preselect-first="true"
                      disabled
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="flex flex-row pt-4 px-4 lg:px-6">
                  <button
                    type="submit"
                    class="text-white bg-yellow-600 hover:bg-green-500 font-bold px-3 py-2 rounded inline-flex items-center content-end mr-5"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
      <home-footer />
    </div>
  </div>
</template>

<script>
import { HomeHeader } from "../../layouts";
import { HomeFooter } from "../../components";
import  preceptory  from "../../assets/json/preceptory.json";

import api from "@/api/apiServices";
import Multiselect from "vue-multiselect";

export default {
  name: "AccountSetup",
  components: {
    HomeFooter,
    HomeHeader,
    Multiselect,
  },
  async mounted() {
    this.getRoleList();
    this.getUserInformation(this.user.attributes);
  },
  data() {
    return {
      user: this.$store.getters["security/currentUser"],
      name: "",
      surname: "",
      birthDate: "",
      joinDate: "",
      phone: "",
      email: "",
      title: "",
      mmhid: "",
      preceptoryId: [],
      selectedRole: "",
      selectedPreceptories: [],
      occupation: "",
      roleList: [],
      preceptoryList: preceptory,
    };
  },
  methods: {
    async getRoleList() {
      api.getRolePermission().then((res) => {
        this.roleList = res.data;
      });
    },

    async getUserInformation(data) {
      this.name = data.name;
      this.title = data["custom:title"];
      this.surname = data.family_name;
      this.email = data.email;

      this.birthDate = data.birthdate;
      this.phone = data.phone_number;
      this.occupation = data["custom:occupation"];
      this.mmhid = data["custom:mmhId"];

      this.selectedRole = JSON.parse(data["custom:role"]).roleName;
      this.joinDate = data["custom:joindate"];

      let temp = JSON.parse(data["custom:preceptoryId"]);
      this.selectedPreceptories = [];
      try {
        temp.forEach((id) => {
          this.selectedPreceptories.push(
            this.preceptoryList.filter((item) => item.id === id).pop()
          );
        });
      } catch {
        this.selectedPreceptories = [];
      }
    },
    async formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    async checkForm() {
      let params = {
        name: this.name,
        surname: this.surname,
        birthdate: this.birthDate,
        joindate: this.joinDate,
        phone: this.phone,
        email: this.email,
        title: this.title,
        mmhId: this.mmhid,
        preceptoryId: this.preceptoryId,
        occupation: this.occupation,
        username: this.user.username,
      };
      for (let i = 0; i < this.roleList.length; i++) {
        const element = this.roleList[i];
        if (element.name == this.selectedRole) {
          var obj = {
            roleId: element.roleId,
            roleName: element.name,
          };
          params.role = obj;
          break;
        }
      }
      this.selectedPreceptories.forEach((item) =>
        this.preceptoryId.push(item.id)
      );
      params.preceptoryId = this.preceptoryId;
      api
        .updateUser(params)
        .then((res) => {
          this.preceptoryId = [];
          if (res.data) {
            this.$fire({
              title: "Edit successful",
              text: res.data.message,
              type: "success",
              timer: 3000,
            });
            this.$router.push({ name: "Preceptory" });
          }
        })
        .catch((error) => {
          this.$fire({
            title: "Failed to edit",
            text: error.message,
            type: "error",
          });
        });
    },
    async resetForm() {
      this.name = "";
      this.surname = "";
      this.birthDate = "";
      this.joinDate = "";
      this.phone = "";
      this.email = "";
      this.title = "";
      this.mmhid = "";
      this.selectedPreceptories = [];
      this.preceptoryId = [];
      this.selectedRole = "";
      this.occupation = "";
    },
  },
};
</script>
<style src="../../styles/vue-multiselect.css"></style>
